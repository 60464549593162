<template>
    <div>
        <errors-bag-list/>

        <b-card>
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button v-if="created && !document.deleted_at" variant="danger" @click="onDestroy">Удалить</b-button>
                    <b-button v-if="document.deleted_at" variant="danger" @click="onRestore">Восстановить</b-button>
                </b-col>
            </b-row>
            <b-card-body>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <template #label>
                                Тип документа <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                            </template>
                            <document-type-select
                                v-model="document.document_type_id"
                                :disabled="disabledForAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <template #label>
                                Сотрудник <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                            </template>
                            <user-input v-model="document.user" :disabled="disabledForAccountant || disabled"/>
                        </b-form-group>

                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <template #label>
                                Период с <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                            </template>
                            <b-form-datepicker
                                v-model="document.start_at"
                                :max="document.end_at"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                :disabled="disabledForAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <template #label>
                                Период по <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                            </template>
                            <b-form-datepicker
                                v-model="document.end_at"
                                :min="document.start_at"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                :disabled="disabledForAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="К выплате">
                            <b-form-input
                                v-model="document.amount"
                                type="number"
                                :disabled="disabledForNonAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Дата выплаты">
                            <b-form-datepicker
                                v-model="document.pay_at"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                :disabled="disabledForAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Комментарий">
                            <b-form-textarea
                                v-model="document.comment"
                                rows="3"
                                :disabled="disabledForAccountant || disabled"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>

            <b-card-footer>
                <b-row>
                    <b-col md="12">
                        <b-button
                            v-if="!document.deleted_at"
                            @click="saveDocument"
                            class="mr-2"
                            variant="success"
                            :disabled="disabled"
                        >
                            Сохранить
                        </b-button>
                        <b-button variant="light" @click="$router.go(-1)">Отмена</b-button>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>

        <b-alert v-if="created" show variant="secondary">
            Создал: {{ document.creator.fullname || document.creator.username }}, {{ moment(document.created_at).format('DD.MM.YYYY HH:mm:ss') }} <br>
            Изменил: {{ document.editor.fullname || document.creator.username }}, {{ moment(document.updated_at).format('DD.MM.YYYY HH:mm:ss') }} <br>
            <template v-if="document.deleted_at">
                Удалил: {{ document.destroyer.fullname || document.creator.username }}, {{ moment(document.deleted_at).format('DD.MM.YYYY HH:mm:ss') }}
            </template>
        </b-alert>
    </div>
</template>

<script>
import moment from 'moment';

import UserInput from '@components/_common/UserInput';
import ControlPanel from '@components/_common/ControlPanel';
import ErrorsBagList from '@components/_common/ErrorsBagList';

import ErrorsBag from '@utils/errorsBag';
import {ROLE_ACCOUNTANT, ROLE_DIRECTOR} from '@utils/Roles';
import {DOCUMENT_ENDPOINT} from '@utils/endpoints';
import {DocumentService} from '@services';
import DocumentTypeSelect from './components/DocumentTypeSelect.vue';

import {VToolbar} from '@ui';

export default {
    name: 'DocumentForm',
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    components: {
        UserInput,
        ControlPanel,
        ErrorsBagList,
        DocumentTypeSelect,
        VToolbar,
    },
    computed: {
        created() {
            return this.document && this.document.id
        },
        disabled() {
            return this.saving || this.deleting || this.loading || (this.document && this.document.blocked) || this.document.deleted_at
        },
        disabledForAccountant() {
            return this.created && this.$auth.user().roles.some(role => [ROLE_ACCOUNTANT].includes(role)) &&
                !this.$auth.user().roles.some(role => [ROLE_DIRECTOR].includes(role))
        },
        disabledForNonAccountant() {
            return !this.$auth.user().roles.some(role => [ROLE_ACCOUNTANT].includes(role)) &&
                !this.$auth.user().roles.some(role => [ROLE_DIRECTOR].includes(role))
        }
    },
    data() {
        return {
            moment,
            document: {
                document_type_id: null,
                user: {id: null},
                start_at: null,
                end_at: null,
                amount: null,
                pay_at: null,
                comment: null,
                blocked: false,
                deleted_at: null,
                created_at: null,
                updated_at: null,
                creator: null,
                editor: null,
                destroyer: null,
            },
            saving: false,
            deleting: false,
            loading: false,
            ErrorsBag,
        }
    },
    methods: {
        async saveDocument() {
            ErrorsBag.discard()

            this.saving = true

            const data = {
                document_type_id: this.document && this.document.document_type_id || null,
                user_id: this.document && this.document.user && this.document.user.id || null,
                start_at: this.document && this.document.start_at,
                end_at: this.document && this.document.end_at,
                amount: Number.parseFloat(this.document && this.document.amount) || null,
                pay_at: this.document && this.document.pay_at,
                comment: this.document && this.document.comment || null,
            };

            const request = this.id
                ? this.$http.put(`${DOCUMENT_ENDPOINT}/${this.id}`, data)
                : this.$http.post(DOCUMENT_ENDPOINT, data);

            try {
                await request;
                this.$bvToast.toast('Документ успешно сохранен', {variant: 'success'});
                this.$router.push({name: "DocumentList"})
            } catch (response) {
                if (response.status === 422) {
                    ErrorsBag.fill(response.body);
                    return;
                }

                this.$bvToast.toast('Не удалось сохранить документ', {variant: 'danger'});
            } finally {
                this.saving = false
            }
        },
        onDestroy() {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите удалить документ?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    cancelVariant: 'light',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
                .then((value) => {
                    if (value) {
                        DocumentService.destroy(this.document.id)
                            .then(() => {
                                this.fetchDocument();
                                this.$bvToast.toast('Удаление документа выполнено успешно', {variant: 'success'});
                            }).catch((response) => {
                            this.$bvToast.toast(response.data.message, {variant: 'danger'});
                        });
                    }
                });
        },
        onRestore() {
            this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите восстановить документ?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    cancelVariant: 'light',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            )
                .then((value) => {
                    if (value) {
                        DocumentService.restore(this.id)
                            .then(() => {
                                this.fetchDocument();
                                this.$bvToast.toast('Восстановление документа выполнено успешно', {variant: 'success'});
                            }).catch(() => {
                            this.$bvToast.toast('Не удалось восстановить документ', {variant: 'danger'});
                        });
                    }
                });
        },
        fetchDocument() {
            ErrorsBag.discard()

            this.loading = true;

            DocumentService.find(this.id, {with: ['user', 'creator', 'editor', 'destroyer']})
                .then((response) => {
                    this.document = response.data;
                })
                .catch((response) => {
                    ErrorsBag.fill(response.body)
                    this.$bvToast.toast('Не удалось загрузить документ', {variant: 'danger'});
                })
                .finally(() => {
                    this.loading = false
                });
        },
        amountFormatter(value) {
            return value
                .toString()
                .replace(",", ".")
                .replace(/[^\d.\-]/g, "");
        },
    },
    mounted() {
        if (this.id) {
            this.fetchDocument();
        }
    },
}
</script>
